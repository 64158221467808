import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "react-bootstrap/Table";

// Components
import Signal from './Signal'
//Assets
import card_back from "../../assets/Ellipse 7417.png";
import dashboardService from "../../services/dashboard";
import { ReactComponent as ProfitLossSvg } from '../../assets/svg/profit-loss.svg';
import { ReactComponent as NavigateIconSvg } from '../../assets/svg/navigate-icon.svg';
import { ReactComponent as MarketPlaceProfitSvg } from "../../assets/svg/market-place-profit.svg";
import { ReactComponent as ReferralProfitSvg } from "../../assets/svg/referral-profit.svg";
// Language
import { tradeInProgressArray } from '../constants'

function Home1() {
  const [select1, setSelect1] = useState("");
  const [select2, setSelect2] = useState("");
  const [select3, setSelect3] = useState("");
  const [exchange, setExchange] = useState("");
  const [date, setDate] = useState("");
  const [t] = useTranslation();
  const [signals, setSignals] = useState([]);
  const [hasExchanges, setHasExchanges] = useState(true);
  useEffect(() => {
    const fetchSignals = () => {
      dashboardService.getSignals(
        (response) => {
          setHasExchanges(response.hasExchanges);
          setSignals(response.signals);
        },
        (error) => {
          console.log(error);
        }
      );
    };

    fetchSignals();

    const interval = setInterval(fetchSignals, 10000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="cards">
        <div className="card">
          <img src={card_back} alt="" />
          <div className="top_sec">
            <div className="card_head">
              <div className="icon">
                <ProfitLossSvg />
              </div>
              <div className="sec_title">
                <h4>{t("Dashboard.home.d")}</h4>
                <p>{t("Dashboard.select1.c")}</p>
              </div>
            </div>
            <Select
              value={select1}
              onChange={(e) => setSelect1(e.target.value)}
              displayEmpty
              defaultValue={"Weekly"}
              className="select_card"
            >
              <MenuItem value="">{t("Dashboard.select1.a")}</MenuItem>
              <MenuItem value={t("Dashboard.select1.b")}>
                {t("Dashboard.select1.b")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.c")}>
                {t("Dashboard.select1.c")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.d")}>
                {t("Dashboard.select1.d")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.e")}>
                {t("Dashboard.select1.e")}
              </MenuItem>
            </Select>
          </div>
          <div className="btm_sec">
            <div className="new_acc">
              <div className="num">$0.00</div>
              <Link to="/exchange" className="btm_connect">
                {t("Dashboard.home.btn1")}
                <NavigateIconSvg />
              </Link>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-overlay">soon</div>
          <img src={card_back} alt="" />
          <div className="top_sec">
            <div className="card_head">
              <div className="icon">
                <MarketPlaceProfitSvg />
              </div>
              <div className="sec_title">
                <h4>{t("Dashboard.home.f")}</h4>
                <p>{t("Dashboard.select1.c")}</p>
              </div>
            </div>
            <Select
              value={select2}
              onChange={(e) => setSelect2(e.target.value)}
              displayEmpty
              defaultValue={"Weekly"}
              className="select_card"
            >
              <MenuItem value="">{t("Dashboard.select1.a")}</MenuItem>
              <MenuItem value={t("Dashboard.select1.b")}>
                {t("Dashboard.select1.b")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.c")}>
                {t("Dashboard.select1.c")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.d")}>
                {t("Dashboard.select1.d")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.e")}>
                {t("Dashboard.select1.e")}
              </MenuItem>
            </Select>
          </div>
          <div className="btm_sec">
            <div className="new_acc">
              <div className="num">$0000</div>
              <Link to="/marketplace" className="btm_connect">
                {t("Dashboard.home.btn2")}
                <NavigateIconSvg />
              </Link>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-overlay">soon</div>
          <img src={card_back} alt="" />
          <div className="top_sec">
            <div className="card_head">
              <div className="icon">
                <ReferralProfitSvg />
              </div>
              <div className="sec_title">
                <h4>{t("Dashboard.home.g")}</h4>
                <p>{t("Dashboard.select1.c")}</p>
              </div>
            </div>
            <Select
              value={select3}
              onChange={(e) => setSelect3(e.target.value)}
              displayEmpty
              defaultValue={"Weekly"}
              className="select_card"
            >
              <MenuItem value="">{t("Dashboard.select1.a")}</MenuItem>
              <MenuItem value={t("Dashboard.select1.b")}>
                {t("Dashboard.select1.b")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.c")}>
                {t("Dashboard.select1.c")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.d")}>
                {t("Dashboard.select1.d")}
              </MenuItem>
              <MenuItem value={t("Dashboard.select1.e")}>
                {t("Dashboard.select1.e")}
              </MenuItem>
            </Select>
          </div>
          <div className="btm_sec">
            <div className="new_acc">
              <div className="num"></div>
              <Link to="/referral" className="btm_connect">
                {t("Dashboard.home.btn3")}
                <NavigateIconSvg />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="trades_progress table-bor">
        <div className="title_sec">
          <h2>{t("Dashboard.home.tb")}</h2>
          <p className="total_unrealized">
            {t("Dashboard.home.tb2")}
            <span>${signals.reduce((total, signal) => total + signal.unrealizedPnl, 0)?.toFixed(2)}</span>
          </p>
        </div>
        <div className="filter" hidden>
          <Select
            value={exchange}
            onChange={(e) => setExchange(e.target.value)}
            displayEmpty
            className="select_filter"
          >
            <MenuItem value="">Binance</MenuItem>
            <MenuItem value="Exchange 2">Spot</MenuItem>
            <MenuItem value="Exchange 3">Binance</MenuItem>
          </Select>
          <Select
            value={date}
            onChange={(e) => setDate(e.target.value)}
            displayEmpty
            className="select_filter"
          >
            <MenuItem value="">{t("Dashboard.select1.a")}</MenuItem>
            <MenuItem value={t("Dashboard.select1.b")}>
              {t("Dashboard.select1.b")}
            </MenuItem>
            <MenuItem value={t("Dashboard.select1.c")}>
              {t("Dashboard.select1.c")}
            </MenuItem>
            <MenuItem value={t("Dashboard.select1.d")}>
              {t("Dashboard.select1.d")}
            </MenuItem>
            <MenuItem value={t("Dashboard.select1.e")}>
              {t("Dashboard.select1.e")}
            </MenuItem>
          </Select>
        </div>
        <div className="trades_table">
          <Table>
            <thead>
              <tr>
                {tradeInProgressArray.map(el => <th key={el.id}>{t(el)}</th>)}
              </tr>
            </thead>
            <tbody>
              {signals.length === 0 && !hasExchanges && (
                <tr>
                  <td colSpan="9">
                    <div className="empty-table">
                      <p>{t("Dashboard.home.nothing")}</p>
                      <Link to="/exchange">{t("Dashboard.home.add")}</Link>
                    </div>
                  </td>
                </tr>
              )}
              {signals.length === 0 && hasExchanges && (
                <tr>
                  <td colSpan="9">
                    <div className="empty-table">
                      <p>{t("Dashboard.home.nothing")}</p>
                    </div>
                  </td>
                </tr>
              )}
              {signals?.map((signal) => (
                <Signal signal={signal} />
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default Home1;
